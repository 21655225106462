<template>
    <div>
        <div class="row my-3">
            <h1 class="col-sm-12">Consultas</h1>
        </div>

        <DataGrid :configuracion="datagrid_config" :data="consultas" @ready="buscar_btns">
            <button class="btn btn-secondary mr-2" @click="obtener_consultas"><i class="fa-solid fa-rotate"></i>
                Actualizar</button>
            <button class="btn btn-primary" @click="show_modal = true"><i class="fa-solid fa-plus"></i> Crear</button>
        </DataGrid>

        <formulario-consulta v-if="show_modal" :prop_consulta="consulta" @close="cerrar_modal" @update="cerrar_modal(true)" />

    </div>
</template>

<script>
import DataGrid from '@/components/DataGridV3.vue';
import apiCondusefConsultas from '@/apps/condusef/api/consultas';
import FormularioConsulta from "@/apps/condusef/pages/Consultas/FormularioConsulta.vue";

export default {
    components: { DataGrid, FormularioConsulta },
    data() {
        return {
            datagrid_config: {
                cols: {
                    numero_folio: 'Folio',
                    trimestre_label: 'Trimestre',
                    estatus_consulta: 'Estatus',
                    created_at: 'Fecha Creación',
                    otros: '',
                },
                mutators: {
                  numero_folio(val, row, vue) {

                    return val && val.length && val.length > 15 ? val.substr(0, 15) + '...' : val;
                  },
                  estatus_consulta(val, row, vue) {
                    return "<span class='" + (val == 1 ? 'badge badge-warning' : 'badge badge-success') + "'>" + (val == 1 ? 'Pendiente' : 'Concluido') + "</span>";
                  },
                  created_at(val, row, vue) {
                    return vue.$moment(val).format('YYYY-MM-DD HH:SS:DD');
                  },
                  otros(val, row, vue) {
                    return '<button class="btn btn-secondary btn-accion" data-consulta="' + vue.$helper.toJson(row) + '"><i class="fa-regular fa-eye"></i></button>'
                  }
                },
                selector: false,
            },
            consultas: [],
            consulta: {
              numero_folio: null
            },
            show_modal: false,
        }
    },
    mounted() {
        this.obtener_consultas();
    },
    methods: {
        async obtener_consultas() {
            try {
                this.consultas = (await apiCondusefConsultas.listar()).data;
            } catch (error) {
                this.$log.error('error', error);
                this.$helper.showAxiosError(error, 'Error');
            }
        },
        buscar_btns() {
            document.querySelectorAll('button.btn-accion').forEach(btn => {
                btn.removeEventListener('click', this.abrir_modal);
                btn.addEventListener('click', this.abrir_modal);
            })
        },
        abrir_modal(event) {
            let btn = event.target;

            if (btn.tagName == 'I')
                btn = btn.parentElement;

            this.consulta = this.$helper.fromJson(btn.dataset.consulta);

            this.show_modal = true;
        },
        cerrar_modal(recargar) {
            if (typeof recargar == ' undefined')
                recargar = false

            this.show_modal = false;
            this.consulta = { numero_folio: null }

            if (recargar)
                this.obtener_consultas();

        }

    },

}
</script>